<template lang="pug">
div(
  class='yotpo yotpo-reviews-carousel [&_.carousel-display-wrapper]:!p-0'
  data-background-color='transparent'
  data-mode='top_rated'
  data-type='product'
  data-count='9'
  data-show-bottomline='1'
  data-autoplay-enabled='1'
  data-autoplay-speed='3000'
  data-show-navigation='1'
)
</template>
<script setup lang="ts">
useYotpo()
</script>
